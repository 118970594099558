import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import moment from 'moment';
import { createStore } from 'vuex-extensions';
import domain from '@/environment';
import rapport from './modules/rapport';
import organisme from './modules/organisme';
import fiche from './modules/fiche';
import salary from './modules/salary';
import salaryTh from './modules/salaryTh';
import facture from './modules/facture';
import regie from './modules/regie';
import notification from './modules/notification';
import rapportPoseur from './modules/rapportPoseur';
import sousRegie from './modules/sousRegie';
import rapportTh from './modules/rapportTh';
import commerciaux from './modules/commerciaux';
import suiviIndependant from './modules/suiviIndependant';
import suiviIndependantTh from './modules/suiviIndependantTh';
import suiviRegie from './modules/suiviRegie';
import suiviRegieTh from './modules/suiviRegieTh';
import calander from './modules/calander';
import recapRegie from './modules/recapRegie';
import recapRegieTh from './modules/recapRegieTh';
import recapCommerciaux from './modules/recapCommerciaux';
import SuiviPaiementTh from './modules/suiviPaiementTh';
import calanderTh from './modules/calanderTh';
import repartitionZone from './modules/repartitionZoneTh';
import filialeTh from './modules/gestionFilialeTh';
import organismeTh from './modules/organismeTh';
import organismeThNew from './modules/organismeThNew';
import gestionVariableTh from './modules/gestionVariableTh';
import appelPaiementObligee from './modules/appelPaiementObligee';
import suiviAcomptes from './modules/suiviAcomptes';
import regieTh from './modules/regieTh';
import factureRegieTh from './modules/factureRegieTh';
import fraisTh from './modules/fraisTh';
import fraisBdd from './modules/fraisBdd';
import fraisBddCommericalCactus from './modules/fraisBddCommericalCactus';
import visionFilialeFrais from './modules/visionFilialeFrais';
import fraisParFilialeTh from './modules/fraisParFilialeTh';
import fraisCactusAlteo from './modules/fraisCactusAlteo';
import bureauEtudeFrais from './modules/bureauEtudeFrais';
import template from './modules/template';
import Router from '../router/index';
import pusherAdmin from '../pusher/admin_pusher';
import pusherExport from '../pusher/export_excel_pusher';
import pusherAdminRegie from '../pusher/admin_regie';
import pusherResponsableDepot from '../pusher/responsable_depot';
import pusherEmployee from '../pusher/employee';
import User from '../models/User';
import Permission from './modules/permission';
import GestionProduits from '../store/modules/gestionProduit';
import gestionCategorie from './modules/gestionCategories';
import gestionTypeSocite from './modules/gestionTypeSocite';
import documentsSociete from './modules/documentsSociete';
import factureLibreTh from './modules/factureLibreTh';
import SettingSocietefilialeSci from './modules/gestionSocieteFilialeSci';
import vueDynamiqueAV from './modules/vueDynamiqueAV';
import statisticsGeneral from './modules/statisticsGeneral';
import EnergieInvest from './modules/EnergieInvest';
import fraisWebAbondance from './modules/fraisWebAbondance';
import fraisBddInterneIhs from './modules/fraisBddInterneIhs';
import fraisResponsableFiliale from './modules/fraisResponsableFiliale';
import simulationFactureFrais from './modules/simulationFactureFrais';
import GestionConfiguration from './modules/gestionConfiguration';
import DashboardFacture from './modules/dashboardFacture';
import globaleFunctionCdg from './modules/globaleFunctionCdg';
import filialeExtraMailing from './modules/filialeExtraMailing';
import pseudoFiliale from './modules/pseudoFiliale';
import mailObligee from './modules/mailObligee';
import masterFilialeTh from './modules/masterFilialeTh';
import role from './modules/role';
import variableMasterFiliale from './modules/variableMasterFiliale';
import fraisThDynamique from './modules/fraisThDynamique';
import exportDynamic from './modules/exportDynamic';
import comptabilite from './modules/comptabilite';
import famillyPermission from './modules/famillyPermission';
import listExportCdg from './modules/listExportCdg';
import uploadFile from './modules/uploadFile';
import configurationMensuel from './modules/configurationMensuel';
import bien from './modules/bien';
import service from './modules/service';
import FactureMensuel from './modules/FactureMensuel';
import factureSCI from './modules/factureSCI';
import mailCdg from './modules/mailCdg';
import bank from './modules/bank';
import TypeReglement from './modules/TypeReglement';
import ConditionPaiement from './modules/ConditionPaiement';
import Reglement from './modules/Reglement';
import lcr from './modules/lcr';
import FactureMaster2 from './modules/FactureMaster2';
import parametreSpecifique from './modules/parametreSpecifique';
import badgeReglement from './modules/badgeReglement';
import factureCommercial from './modules/factureCommercial';
import statisticOblige from './modules/statisticOblige';
import appelPaiementCommerciaux from './modules/appelPaiementCommerciaux';
import rapportThStore from './modules/rapportThStore';
import gestionGroupeTemplateTh from './modules/gestionGroupeTemplateTh';
import avoirPartielfrom from './modules/avoirPartiel';
import refreshFacture from './modules/refreshFacture';
import gestionSocieteAtraiter from './modules/gestionSocieteAtraiter';
import gestionClientPassage from './modules/gestionClientPassage';
import TemplateFacture from './modules/TemplateFacture';
import sequence from './modules/sequence';
import rib from './modules/rib';
import societeFactures from './modules/societeFactures';
import pixel from './modules/pixel';
import planTiers from './modules/planTiers';

Vue.use(Vuex);
export default createStore(Vuex.Store, {
  state: {
    User: JSON.parse(localStorage.getItem('User-Details'))
      ? JSON.parse(localStorage.getItem('User-Details'))
      : null,
    loadingLogin: false,
    loginError: null,
    loadingUpdateInfoUser: false,
    errorInUpdateUser: null,
    currentType:
      localStorage.getItem('Current-Type') &&
        localStorage.getItem('Current-Type') != undefined &&
        JSON.parse(localStorage.getItem('Current-Type'))
        ? JSON.parse(localStorage.getItem('Current-Type'))
        : null,
    users: [],
    usersWithoutPermission: [],
    userLoading: false,
    loadingUserWithoutPermission: false,
    usersCount: 0,
    usersTotalRows: null,
    vibilityMenuFact: false,
    listMonth: [
      { value: 1, text: 'Janvier' },
      { value: 2, text: 'Février' },
      { value: 3, text: 'Mars' },
      { value: 4, text: 'Avril' },
      { value: 5, text: 'Mai' },
      { value: 6, text: 'Juin' },
      { value: 7, text: 'Juillet' },
      { value: 8, text: 'Août' },
      { value: 9, text: 'Septembre' },
      { value: 10, text: 'October' },
      { value: 11, text: 'Nouvembre' },
      { value: 12, text: 'Décembre' }
    ],
    currentInterfaceFrais: null,
    showNotifExportFile: false
  },
  getters: {
    //login
    getloginLoding: state => state.loadingLogin,
    getloginError: state => state.loginError,
    getUserData: state => state.User,
    getLoadingUpdateInfoUser: state => state.loadingUpdateInfoUser,
    getErrorInUpdateUser: state => state.errorInUpdateUser,
    getCurrentType: state => state.currentType,
    getUserLoading: state => state.userLoading,
    getUsersCount: state => state.usersCount,
    users: state => state.users,
    getUsersTotlaRows: state => state.usersTotalRows,
    usersWithoutPermission: state => state.usersWithoutPermission,
    loadingUserWithoutPermission: state => state.loadingUserWithoutPermission,
    getListMonth: state => state.listMonth,

    checkPermission: state => key => {
      if (state.User && state.User.role && state.User.role == 'admin') {
        return true;
      } else {
        if (
          state.User &&
          state.User.permissions &&
          state.User.permissions.indexOf(key) != -1
        ) {
          return true;
        }
        return false;
      }
    },
    formateDateGetters: state => value => {
      if (value == '' || value == null) {
        return '-';
      } else if (moment(value, 'DD-MM-YYYY', true).isValid()) {
        return value;
      } else {
        return moment(value, 'YYYY-MM-DD').format('DD-MM-YYYY');
      }
    },
    formateGeneraleDateGetters: state => value => {
      if (value == '' || value == null) {
        return '-';
      } else {
        return moment(value).format('DD-MM-YYYY');
      }
    },
    //role
    isResposableWebAbondance: state => {
      return state.User?.role && state.User.role == 'responsable_web_abondance';
    },
    isSuperAdminGetter: state => {
      return state.User?.role && state.User.role == 'admin';
    },
    isResponsableFiliale: state => {
      return state.User?.role && state.User.role == 'responsable_filiale';
    },
    isResponsableAlteao: state => {
      return state.User?.role && state.User.role == 'responsable_acteo';
    },
    isReponsableUbcube: state => {
      return (
        state.User?.role && state.User.role == 'responsable_upcube_technologies'
      );
    },
    isUserIso33: state => {
      return state.User?.role && state.User.role == 'utilisateur_iso33';
    },
    isUserIsodit: state => {
      return state.User?.role && state.User.role == 'utilisateur_isodit';
    },
    isUserEffimax: state => {
      return state.User?.role && state.User.role == 'utilisateur_effimax';
    },
    isUserBe: state => {
      return state.User?.role && state.User.role == 'responsable_bureau_etude';
    },
    isUserCWFCONSULTING: state => {
      return (
        state.User?.role && state.User.role == 'utilisateur_CWF_CONSULTING'
      );
    },
    responsableValidation: state => {
      return state.User?.role && state.User.role == 'responsable_validation';
    },
    isAdminRegie: state => {
      return state.User?.role && state.User.role == 'admin_regie';
    },
    isCommercialRegie: state => {
      return state.User?.role && state.User.role == 'commercial_regie';
    },
    isComptabilite: state => {
      return state.User?.role && state.User.role == 'contabilite';
    },
    isControleGestion: state => {
      return (
        state.User?.role && state.User.role == 'responsable_controle_de_gestion'
      );
    },
    getShowNotifExportFile: state => state.showNotifExportFile,
    computedRouterLinkFacture: state => state.vibilityMenuFact,
    countNonEmptyArrays: state => (...arrays) => {
      const nonEmptyArrays = arrays.filter(array => array.length > 0);
      let length = nonEmptyArrays.length
      return length == 0 ? 12 : length > 4 ? 4 : 12 / length;
    }
  },
  mutations: {
    UPDATE_VISIBILITY_MENU_FACT(state, payload) {
      state.vibilityMenuFact = payload
    },
    //login setters
    SET_LODING_LOGIN(state, payload) {
      state.loadingLogin = payload;
    },
    SET_ERROR_LOGIN(state, payload) {
      state.loginError = payload;
    },
    SET_RESET_ERROR(state, payload) {
      state.loginError = null;
    },
    SET_USER(state, payload) {
      if (payload && payload.permission) {
        state.User.permissions = payload.data.permissions;
      } else {
        state.User = payload;
      }
    },
    SET_LOADING_INFO_USER(state, payload) {
      state.loadingUpdateInfoUser = payload;
    },
    SET_ERROR_EDIT_USER(state, payload) {
      state.errorInUpdateUser = payload;
    },
    CHANGE_CURRENT_TYPE(state, payload) {
      if (payload?.reload) {
        state.currentType = payload.currentType;
        localStorage.setItem(
          'Current-Type',
          JSON.stringify(payload.currentType)
        );
      } else {
        localStorage.setItem('Current-Type', JSON.stringify(payload));
        state.currentType = payload;
        if (state.currentType?.value == 'libres') {
          if (this.getters.checkPermission('FL')) {
            Router.replace('/facture-libre').catch(() => { });
          } else {
            Router.replace('/').catch(() => { });
          }
        } else if (state.currentType?.value == 'sci') {
          if (this.getters.checkPermission('FSTH')) {
            Router.replace('/facture-sci').catch(() => { });
          } else {
            Router.replace('/').catch(() => { });
          }
        } else {
          Router.replace('/cdg').catch(() => { });
        }
      }
    },
    ADD_USER(state, payload) {
      state.users.push(User.create(payload));
    },
    SET_USERS(state, payload) {
      state.users = payload.map(item => User.create(item));
      // state.usersCount = payload.meta.pagination.total;
    },
    USER_LOADING(state, payload) {
      state.userLoading = payload;
    },
    REMOVE_USEUR(state, id) {
      for (let index = 0; index < state.users.length; index++) {
        if (state.users[index].id == id) {
          state.users.splice(index, 1);
          break;
        }
      }
    },
    SET_RESTAURER_USERS(state, payload) {
      state.users = state.users.filter(user => {
        return user.id != payload.id;
      });
    },
    UPDATE_USER(state, { payload, response }) {
      payload.update(response);
    },
    SET_USERS_WITHOUT_PERMISSION(state, payload) {
      state.usersWithoutPermission = payload;
    },
    USER_WITHOUT_PERMISSION_LOADING(state, payload) {
      state.loadingUserWithoutPermission = payload;
    },
    SET_CURENT_INTERFECE_FRAIS(state, payload) {
      state.currentInterfaceFrais = payload;
    },
    SET_USERS_TOTAL_ROWS(state, payload) {
      state.usersTotalRows = payload;
    },
    SET_USER_RAFRECH_CACHE(state, payload) {
      localStorage.clear();
      this.reset();
      if (this.state.User !== null) {
        this.commit('SET_USER', null);
      }
      pusherAdminRegie.destroyed();
      pusherAdmin.destroyed();
      pusherExport.destroyed();
      Router.replace('/login').catch(() => { });
    }
  },

  actions: {
    updateVisibiliteMenuFacture({ commit }, payload) {
      commit('UPDATE_VISIBILITY_MENU_FACT', payload)
    },
    resetErrorUserUpdate({ commit }) {
      commit('SET_ERROR_EDIT_USER', []);
    },
    resetErrorLogin({ commit }) {
      commit('SET_RESET_ERROR', null);
    },
    async login({ commit }, payload) {
      commit('SET_ERROR_LOGIN', null);
      commit('SET_LODING_LOGIN', true);
      try {
        const params = new URLSearchParams();
        params.append('email', payload.email);
        params.append('password', payload.password);
        const getToken = await axios.post(domain + `/api/auth/login`, params, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Accept: 'application/json'
          }
        });
        const whoAmI = await axios.get(domain + `/api/auth/whoAmI`, {
          headers: {
            Authorization: `Bearer ${getToken.data.access_token}`
          }
        });
        const User = {
          access_token: getToken.data.access_token,
          ...whoAmI.data.iAm
        };
        localStorage.clear();
        localStorage.setItem('User-Details', JSON.stringify(User));
        commit('SET_USER', User);
        setTimeout(() => {
          if (this.state.User) {
            pusherExport.getNotificationExportFile();
            if (this.state.User.role == 'admin') {
              pusherAdmin.getnotificationForAdmin();
              pusherAdmin.checkanalyse();
            }
            Router.replace('/').catch(() => { });
          }
        });
        commit('SET_LODING_LOGIN', false);
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          commit('SET_ERROR_LOGIN', error.response.data.message);
        }
        commit('SET_LODING_LOGIN', false);
      }
    },
    logOut() {
      localStorage.clear();
      this.reset();
      if (this.state.User !== null) {
        this.commit('SET_USER', null);
      }
      pusherAdminRegie.destroyed();
      pusherAdmin.destroyed();
      pusherExport.destroyed();
      Router.replace('/login').catch(() => { });
    },
    async verificationUser({ commit }, payload) {
      try {
        const params = new URLSearchParams();
        params.append('email', this.state.User.email);
        params.append('password', payload.password);
        const getToken = await axios.post(domain + `/api/auth/login`, params, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Accept: 'application/json'
          }
        });
        if (getToken.data && getToken.data.access_token) {
          return true;
        } else {
          return false;
        }
      } catch (error) {
        commit('SET_ERROR_EDIT_USER', error.response.data.message);
        commit('SET_LOADING_INFO_USER', false);
        return false;
      }
    },
    async forgotPassword({ }, payload) {
      try {
        const response = await axios.post(
          domain + `/api/auth/forgot_password`,
          payload,
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
              Accept: 'application/json'
            }
          }
        );

        return {
          success: true,
          response: response.data.message
        };
      } catch (error) {
        return {
          success: false,
          error: error.response.data.message
        };
      }
    },

    async editUserInfo({ commit }, payload) {
      commit('SET_ERROR_EDIT_USER', null);
      commit('SET_LOADING_INFO_USER', true);

      try {
        let params = new FormData();
        params.append('id', payload.id);
        if (payload && payload.password) {
          params.append('password', payload.password);
        }
        if (payload && payload.password_confirmation) {
          params.append('password_confirmation', payload.password_confirmation);
        }
        if (payload && payload.name) {
          params.append('name', payload.name);
        }
        if (payload && payload.email) {
          params.append('email', payload.email);
        }
        if (typeof payload.photo == 'object' && payload.photo != null) {
          params.append('photo', payload.photo);
        }
        const responce = await axios.post(
          domain + `/api/editResponsableInfo`,
          params,
          {
            headers: {
              Authorization: `Bearer ${this.state.User.access_token}`
            }
          }
        );
        var user = JSON.parse(localStorage.getItem('User-Details'));
        user.access_token = responce.data.token;
        user.email = responce.data.user.email;
        user.photo = responce.data.user.photo;
        user.name = responce.data.user.name;
        localStorage.setItem('User-Details', JSON.stringify(user));
        commit('SET_LOADING_INFO_USER', false);
        return true;
      } catch (error) {
        commit('SET_ERROR_EDIT_USER', error.response.data.errors.password);
        commit('SET_LOADING_INFO_USER', false);
        return false;
      }
    },
    async getToken({ commit, dispatch }) {
      try {
        const whoAmI = await axios.get(domain + `/api/auth/whoAmI`, {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        });
        let User = JSON.parse(localStorage.getItem('User-Details'));
        User.permissions = whoAmI.data.iAm.permissions;
        User.filiales = whoAmI.data.iAm.filiales;
        User.master_filiales = whoAmI.data.iAm.master_filiales;
        User.is_responsable_master = whoAmI.data.iAm.is_responsable_master;
        User.refresh = whoAmI.data.iAm.refresh;
        localStorage.setItem('User-Details', JSON.stringify(User));
        commit('SET_USER', { data: whoAmI.data.iAm, permission: true });
        return true;
      } catch (error) {
        if (error.response && error.response.status === 401) {
          dispatch('logOut');
        }

        return false;
      }
    },
    async getTokenAnotherAccount({ commit }, payload) {
      try {
        const response = await axios.get(
          domain + `/api/auth/super_login/${payload}`,
          {
            headers: {
              Authorization: `Bearer ${this.state.User.access_token}`
            }
          }
        );
        const whoAmI = await axios.get(domain + `/api/auth/whoAmI`, {
          headers: {
            Authorization: `Bearer ${response.data.token}`
          }
        });
        localStorage.clear();
        const User = {
          access_token: response.data.token,
          ...whoAmI.data.iAm
        };
        localStorage.setItem('User-Details', JSON.stringify(User));
        commit('SET_USER', User);
        setTimeout(() => {
          if (this.state.User) {
            pusherExport.getNotificationExportFile();
            Router.replace('/cdg').catch(() => { });
          }
        });
        return { success: true };
      } catch (error) {
        if (error.response && error.response.status === 401) {
          dispatch('logOut');
        }
        return { success: false };
      }
    },
    ChangeCurrentTpe({ commit }, payload) {
      commit('CHANGE_CURRENT_TYPE', payload);
    },
    setCurrentTpe({ state, commit }, payload) {
      state.currentType = payload;
    },
    //add New User
    async addUser({ commit }, payload) {
      const body = {
        // first_name: payload.prenom,
        name: payload.name,
        email: payload.email,
        password: payload.password,
        profile: payload.profile,
        // password_confirmation: payload.confirmationPassword,
        role: payload.role,
        societe_traitement: payload.societe_traitement.map(i => i.id),
        filiales: payload.filiales.map(i => i.id)
      };
      if (payload.phone && payload.phone.length) {
        body.phone = payload.phone;
      }
      try {
        const response = await axios.post(domain + `/api/add_user `, body, {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        });
        commit('ADD_USER', response.data.data);
        return {
          success: true
        };
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          return {
            success: false,
            error: error.response.data.error
          };
        }
        return {
          success: false,
          error: 'Une erreur est survenue'
        };
      }
    },
    //fetch all user
    async getUsers({ commit }, payload) {
      commit('USER_LOADING', true);
      let params = { deleted: payload.deleted };
      if (payload?.role != null) {
        params.role = payload.role;
      }
      if (payload?.search != null) {
        params.search = payload.search;
      }
      if (payload?.page != null) {
        params.page = payload.page;
      }
      if (payload?.per_page != null) {
        params.per_page = payload.per_page;
      }
      try {
        const response = await axios.get(domain + '/api/all_user', {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: params
        });
        if (payload?.page != null) {
          commit('SET_USERS', response.data.data.data);
          commit('SET_USERS_TOTAL_ROWS', response.data.data.total);
        } else {
          commit('SET_USERS', response.data.data);
        }
        commit('USER_LOADING', false);
        return {
          success: true
        };
      } catch (error) {
        commit('USER_LOADING', false);
        if (
          error.response &&
          error.response.data &&
          error.response.data.error &&
          error.response.data.error.messages
        ) {
          return {
            success: false,
            error: error.response.data.error.messages
          };
        }
        return {
          success: false,
          error: 'Une erreur est survenue'
        };
      }
    },
    //update user
    async updateUser({ commit }, payload) {
      const body = {
        id: payload.userToUpdate.id,
        name: payload.userToUpdate.full_name,
        email: payload.userToUpdate.email,
        profile: payload.userToUpdate.profil_id,
        role: payload.userToUpdate.type,
        phone: payload.userToUpdate.phone
      };
      if (payload.userToUpdate.password) {
        body.password = payload.userToUpdate.password;
      }
      if (payload.userToUpdate.societe_traitement.length) {
        body.societe_traitement = payload.userToUpdate.societe_traitement.map(
          i => i.id
        );
      }
      if (payload.userToUpdate.filiales.length) {
        body.filiales = payload.userToUpdate.filiales.map(
          i => i.id
        );
      }
      try {
        const response = await axios.post(domain + '/api/update_user', body, {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        });
        commit('UPDATE_USER', {
          payload: payload.old,
          response: response.data.data
        });
        return {
          success: true
        };
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.error &&
          error.response.data.error.messages
        ) {
          return {
            success: false,
            error: error.response.data.error.messages
          };
        }
        return {
          success: false,
          error: ['Une erreur est survenue']
        };
      }
    },
    async deleteUser({ commit }, payload) {
      try {
        await axios.post(
          domain + '/api/delete_user/' + payload,
          {},
          {
            headers: {
              Authorization: `Bearer ${this.state.User.access_token}`
            }
          }
        );
        commit('REMOVE_USEUR', payload);
        return {
          success: true
        };
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.error &&
          error.response.data.error.messages
        ) {
          return {
            success: false,
            error: error.response.data.error.messages
          };
        }
        return {
          success: false,
          error: ['Une erreur est survenue']
        };
      }
    },
    //restaure user
    async restaurerUser({ commit }, payload) {
      try {
        await axios.post(
          domain + '/api/restore_user/' + payload.id,
          {},
          {
            headers: {
              Authorization: `Bearer ${this.state.User.access_token}`
            }
          }
        );
        commit('SET_RESTAURER_USERS', payload);
        return {
          success: true
        };
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.error &&
          error.response.data.error.messages
        ) {
          return {
            success: false,
            error: error.response.data.error.messages
          };
        }
        return {
          success: false,
          error: ['Une erreur est survenue']
        };
      }
    },
    //fetch all user without permission
    async getUsersWithoutPermission({ commit }) {
      commit('USER_WITHOUT_PERMISSION_LOADING', true);
      try {
        const response = await axios.get(
          domain + '/api/users_without_permissions',
          {
            headers: {
              Authorization: `Bearer ${this.state.User.access_token}`
            }
          }
        );
        commit('SET_USERS_WITHOUT_PERMISSION', response.data.data);
        commit('USER_WITHOUT_PERMISSION_LOADING', false);
        return {
          success: true
        };
      } catch (error) {
        commit('USER_WITHOUT_PERMISSION_LOADING', false);
        if (
          error.response &&
          error.response.data &&
          error.response.data.error &&
          error.response.data.error.messages
        ) {
          return {
            success: false,
            error: error.response.data.error.messages
          };
        }
        return {
          success: false,
          error: 'Une erreur est survenue'
        };
      }
    },
    //update frais current interface
    updateInterface({ commit }, payload) {
      commit('SET_CURENT_INTERFECE_FRAIS', payload);
    },
    async clearCacheForUser({ commit }, payload) {
      try {
        await axios.get(domain + '/api/auth/user_clear_cache/' + payload, {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        });
        commit('SET_USER_RAFRECH_CACHE', true);
        return {
          success: true
        };
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.error &&
          error.response.data.error.messages
        ) {
          return {
            success: false,
            error: error.response.data.error.messages
          };
        }
        return {
          success: false,
          error: ['Une erreur est survenue']
        };
      }
    }
  },

  modules: {
    rapport,
    organisme,
    fiche,
    salary,
    salaryTh,
    facture,
    regie,
    notification,
    rapportPoseur,
    sousRegie,
    rapportTh,
    commerciaux,
    suiviIndependant,
    suiviIndependantTh,
    suiviRegie,
    suiviRegieTh,
    calander,
    recapRegie,
    recapRegieTh,
    recapCommerciaux,
    SuiviPaiementTh,
    Permission,
    calanderTh,
    repartitionZone,
    filialeTh,
    organismeTh,
    gestionVariableTh,
    appelPaiementObligee,
    suiviAcomptes,
    regieTh,
    factureRegieTh,
    fraisTh,
    fraisBdd,
    visionFilialeFrais,
    template,
    fraisParFilialeTh,
    fraisCactusAlteo,
    fraisBddCommericalCactus,
    bureauEtudeFrais,
    GestionProduits,
    gestionCategorie,
    documentsSociete,
    factureLibreTh,
    statisticsGeneral,
    EnergieInvest,
    SettingSocietefilialeSci,
    fraisWebAbondance,
    fraisBddInterneIhs,
    fraisResponsableFiliale,
    simulationFactureFrais,
    vueDynamiqueAV,
    GestionConfiguration,
    organismeThNew,
    DashboardFacture,
    globaleFunctionCdg,
    filialeExtraMailing,
    pseudoFiliale,
    mailObligee,
    masterFilialeTh,
    variableMasterFiliale,
    role,
    exportDynamic,
    comptabilite,
    fraisThDynamique,
    bien,
    famillyPermission,
    gestionTypeSocite,
    listExportCdg,
    uploadFile,
    configurationMensuel,
    service,
    FactureMensuel,
    factureSCI,
    mailCdg,
    bank,
    TypeReglement,
    ConditionPaiement,
    Reglement,
    lcr,
    FactureMaster2,
    parametreSpecifique,
    badgeReglement,
    factureCommercial,
    appelPaiementCommerciaux,
    statisticOblige,
    rapportThStore,
    gestionGroupeTemplateTh,
    avoirPartielfrom,
    refreshFacture,
    gestionSocieteAtraiter,
    gestionClientPassage,
    TemplateFacture,
    sequence,
    rib,
    societeFactures,
    pixel,
    planTiers
  }
});
