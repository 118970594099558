import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store/index';

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () =>
      import(/* webpackChunkName: "Login" */ '../views/login.vue'),
    beforeEnter: (to, from, next) => {
      if (!store.state.User) {
        next();
      } else {
        next('/404');
      }
    }
  },
  {
    path: '/',
    name: '',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
    beforeEnter: (to, from, next) => {
      if (store.state.User) {
        next();
      } else {
        next('/login');
      }
    },
    children: [
      {
        path: '',
        name: 'index',
        component: () =>
          import(/* webpackChunkName: "index" */ '../views/index.vue'),
        beforeEnter: (to, from, next) => {
          if (store.state.User) {
            next();
          } else {
            next('/login');
          }
        },
        children: [
          {
            path: '/',
            name: 'static-global',
            component: () =>
              import(
                /* webpackChunkName: "index" */ '../components/Statistiques/index.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (permission('SGCDG') || permission('GOTHS')) {
                next();
              } else {
                next('/cdg');
              }
            }
          },

          {
            path: '/404',
            name: '404',
            component: () =>
              import(/* webpackChunkName: "404" */ '../views/NotFound.vue')
          },
          {
            path: '/cdg',
            name: 'cdg',
            component: () =>
              import(/* webpackChunkName: "cdg" */ '../views/CDG.vue')
          },
          {
            path: '/insertion-bdd',
            name: 'analyse',
            component: () =>
              import(
                /* webpackChunkName: "insertion-bdd" */ '../views/UploadFile.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (permission('IBD')) {
                next();
              } else {
                next('/404');
              }
            }
          },
          {
            path: '/add-facture',
            component: () =>
              import(
                /* webpackChunkName: "add-facture-libre" */ '../views/factureLibre/AddFactureLibreNew.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (permission('FLCNF')) {
                next();
              } else {
                next('/404');
              }
            }
          },
          {
            path: '/update-facture/:id',
            // name: "update-facture",
            component: () =>
              import(
                /* webpackChunkName: "update-facture-libre" */ '../views/factureLibre/updateFactureLibreNew.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (permission('FLMF')) {
                next();
              } else {
                next('/404');
              }
            }
          },
          {
            path: '/duplicate-facture/:id',
            // name: "update-facture",
            component: () =>
              import(
                /* webpackChunkName: "duplicate-facture-libre" */ '../views/factureLibre/DuplicateFactureLibreNew.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (permission('FLDF')) {
                next();
              } else {
                next('/404');
              }
            }
          },
          {
            path: '/display-facture/:id',
            // name: "update-facture",
            component: () =>
              import(
                /* webpackChunkName: "update-facture-libre" */ '../views/factureLibre/FactureInterface.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (true) {
                next();
              } else {
                next('/404');
              }
            }
          },
          {
            path: '/display-facture-template',
            // name: "update-facture",
            component: () =>
              import(
                /* webpackChunkName: "update-facture-libre" */ '../views/factureLibre/FactureInterfaceTemplate.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (true) {
                next();
              } else {
                next('/404');
              }
            }
          },
          {
            path: '/facture/:id',
            // name: "update-facture",
            component: () =>
              import(
                /* webpackChunkName: "update-facture-libre" */ '../views/factureLibre/AfficheFacture.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (permission('FLAF')) {
                next();
              } else {
                next('/404');
              }
            }
          },

          {
            path: 'regies-th',
            name: 'regies-th',
            component: () =>
              import(
                /* webpackChunkName: "regies-th" */ '../views/ThProject/Regie/index.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (permission('GDCTH')) {
                next();
              } else {
                next('/404');
              }
            },
            children: [
              {
                path: 'gestion-aaf',
                name: 'gestionAAF',
                component: () =>
                  import(
                    /* webpackChunkName: "gestionAAF" */ '../views/ThProject/Regie/gestionAAF.vue'
                  ),
                beforeEnter: (to, from, next) => {
                  if (permission('GDCTH')) {
                    next();
                  } else {
                    next('/404');
                  }
                }
              },

              {
                path: 'suivi-regie-th',
                name: 'SuiviRegieTh',
                component: () =>
                  import(
                    /* webpackChunkName: "SuiviRegieTh" */ '../views/ThProject/Regie/suiviRegie.vue'
                  ),
                beforeEnter: (to, from, next) => {
                  if (permission('GDCTH')) {
                    next();
                  } else {
                    next('/404');
                  }
                }
              },
              {
                path: 'recap-regie-th',
                name: 'RecapRegieTh',
                component: () =>
                  import(
                    /* webpackChunkName: "RecapRegieTh" */ '../views/ThProject/Regie/recapRegie.vue'
                  ),
                beforeEnter: (to, from, next) => {
                  if (permission('GDCTH')) {
                    next();
                  } else {
                    next('/404');
                  }
                }
              }
            ]
          },

          {
            path: 'gestion-aaf-regie',
            name: 'gestionAAFRegie',
            component: () =>
              import(
                /* webpackChunkName: "gestionAAFRegie" */ '../views/ThProject/Regie/gestionAAF.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (isRegie()) {
                next();
              } else {
                next('/404');
              }
            }
          },

          {
            path: 'gestion-facture-commercial-regie',
            name: 'GestionFactureCommercialRegie',
            component: () =>
              import(
                /* webpackChunkName: "GestionFactureCommercialRegie" */ '../views/Regie/gestionFactureComercialRegie.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (isCommercialRegie()) {
                next();
              } else {
                next('/404');
              }
            }
          },
          {
            path: 'all-notification',
            name: 'Notification',
            component: () =>
              import(
                /* webpackChunkName: "Notification" */ '../views/Notification.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (isUser) {
                next();
              } else {
                next('/404');
              }
            }
          },
          {
            path: 'statistique',
            name: 'statistique',
            component: () =>
              import(
                /* webpackChunkName: "statistique" */ '../views/Statistique/index.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (isSuperAdmin() || isRegie() || permission('Statistique')) {
                next();
              } else {
                next('/404');
              }
            },
            children: [
              {
                path: 'employeur',
                name: 'employeur',
                component: () =>
                  import(
                    /* webpackChunkName: "employeur" */ '../views/Statistique/StatistiqueEmploye.vue'
                  ),
                beforeEnter: (to, from, next) => {
                  if (isSuperAdmin() || permission('Statistique')) {
                    next();
                  } else {
                    next('/404');
                  }
                }
              },
              {
                path: 'regie',
                name: 'regie',
                component: () =>
                  import(
                    /* webpackChunkName: "regie" */ '../views/Statistique/StatistiqueRegie.vue'
                  ),
                beforeEnter: (to, from, next) => {
                  if (isSuperAdmin() || permission('Statistique')) {
                    next();
                  } else {
                    next('/404');
                  }
                }
              },
              {
                path: 'sousregie',
                name: 'sousregie',
                component: () =>
                  import(
                    /* webpackChunkName: "sousregie" */ '../views/Statistique/StatistiqueSousRegie.vue'
                  ),
                beforeEnter: (to, from, next) => {
                  if (isRegie()) {
                    next();
                  } else {
                    next('/404');
                  }
                }
              }
            ]
          },
          {
            path: '/rapportTh',
            name: 'rapportTh',
            component: () =>
              import(
                /* webpackChunkName: "rapportTh" */ '../views/ThProject/RapportTh/RapportThNew.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (permission('A&VTH')) {
                next();
              } else {
                next('/404');
              }
            }
          },
          {
            path: 'facture-libre',
            name: 'facture-libre',
            meta: {
              tab: 'tab-1'
            },
            component: () =>
              import(
                /* webpackChunkName: "factureLibre-index" */ '../views/factureLibre/index.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (permission('FL')) {
                next();
              } else {
                next('/404');
              }
            }
          },
          {
            path: '/gestionFactureCommerciaux',
            name: 'gestionFactureCommerciaux',
            component: () =>
              import(
                /* webpackChunkName: "gestionFactureCommerciaux" */ '../views/Employe/gestionFactureCommerciaux.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (isCommerciaux()) {
                next();
              } else {
                next('/404');
              }
            }
          },
          {
            path: 'obligee',
            name: 'obligee',
            component: () =>
              import(
                /* webpackChunkName: "obligee" */ '../views/ThProject/oblige/index.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (permission('FOTH')) {
                next();
              } else {
                next('/404');
              }
            },
            children: [
              {
                path: 'gestion-appels-paiement-obligee',
                name: 'gestionAppelPaiementObligee',
                component: () =>
                  import(
                    /* webpackChunkName: "gestionAppelPaiementObligee" */ '../views/ThProject/oblige/gestionAppelPaiementObligee.vue'
                  ),
                beforeEnter: (to, from, next) => {
                  if (permission('GAPTH')) {
                    next();
                  } else {
                    next('/404');
                  }
                }
              }
            ]
          },
          {
            path: 'commercial',
            name: 'commercial',
            component: () =>
              import(
                /* webpackChunkName: "obligee" */ '../views/commercial/index.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (permission('FC')) {
                next();
              } else {
                next('/404');
              }
            },
            children: [
              {
                path: 'facture-commercial',
                name: 'factureCommercial',
                component: () =>
                  import(
                    /* webpackChunkName: "factureCommercial" */ '../views/commercial/factureCommerciaux.vue'
                  ),
                beforeEnter: (to, from, next) => {
                  if (permission('FCTH')) {
                    next();
                  } else {
                    next('/404');
                  }
                }
              },
              {
                path: 'appel-paiement',
                name: 'appel-paiement',
                meta: {
                  tab: 'tab-5'
                },
                component: () =>
                  import(
                    /* webpackChunkName: "appelPaiement" */ '../views/commercial/AppelPaiement.vue'
                  ),
                beforeEnter: (to, from, next) => {
                  if (permission('GAPC')) {
                    next();
                  } else {
                    next('/404');
                  }
                }
              }
            ]
          },
          {
            path: 'commerciaux',
            name: 'commerciaux',
            component: () =>
              import(
                /* webpackChunkName: "commerciaux" */ '../views/ThProject/commerciaux/index.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (permission('GDCTH')) {
                next();
              } else {
                next('/404');
              }
            },
            children: [
              {
                path: 'suivi-acomptes',
                name: 'suiviAcomptes',
                component: () =>
                  import(
                    /* webpackChunkName: "suiviAcomptes" */ '../views/ThProject/commerciaux/suiviAcomptes.vue'
                  ),
                beforeEnter: (to, from, next) => {
                  if (permission('GDCTH')) {
                    next();
                  } else {
                    next('/404');
                  }
                }
              },
              {
                path: 'validation-paie',
                name: 'validation-paie-th',
                component: () =>
                  import(
                    /* webpackChunkName: "validationPaieTh" */ '../views/ThProject/commerciaux/ValidationSalaire.vue'
                  ),
                beforeEnter: (to, from, next) => {
                  if (permission('GDCTH')) {
                    next();
                  } else {
                    next('/404');
                  }
                }
              },
              {
                path: 'validation-aff',
                name: 'validationAaf',
                component: () =>
                  import(
                    /* webpackChunkName: "validationAaf" */ '../views/ThProject/commerciaux/ValidationAaf.vue'
                  ),
                beforeEnter: (to, from, next) => {
                  if (permission('GDCTH')) {
                    next();
                  } else {
                    next('/404');
                  }
                }
              },
              {
                path: 'suivi-paiement-independant',
                name: 'suiviPaiementIndependant',
                component: () =>
                  import(
                    /* webpackChunkName: "suiviPaiementIndependant" */ '../views/ThProject/commerciaux/suiviIndependant.vue'
                  ),
                beforeEnter: (to, from, next) => {
                  if (permission('GDCTH')) {
                    next();
                  } else {
                    next('/404');
                  }
                }
              },
              {
                path: 'recap-commerciaux-par-filiale',
                name: 'recapCommerciauxParFiliale',
                component: () =>
                  import(
                    /* webpackChunkName: "recapCommerciauxParFiliale" */ '../views/ThProject/commerciaux/recapCommerciaux.vue'
                  ),
                beforeEnter: (to, from, next) => {
                  if (permission('GDCTH')) {
                    next();
                  } else {
                    next('/404');
                  }
                }
              }
            ]
          },
          {
            path: 'frais-th',
            name: 'fraisTh',
            meta: {
              tab: 'tab-0'
            },
            component: () =>
              import(
                /*WebpackChunName: "fraisTh" */ '../views/ThProject/frais/index.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (
                permission('SFTH') ||
                permission('GGDFTH') ||
                // permission('GMFTH') ||
                // permission('GMFTH2') ||
                permission('GCTH') ||
                permission('GRHTH')
                // permission('GFMTH')
              ) {
                next();
              } else {
                next('/404');
              }
            },
            children: [
              {
                path: 'simulation-facture-frais',
                name: 'simulationFactureFrais',
                meta: { tab: 'tab-0' },
                component: () =>
                  import(
                    /*WebpackChunName: "simulationFactureFrais" */ '../views/ThProject/frais/Simulation/simulationFrais.vue'
                  ),
                beforeEnter(to, from, next) {
                  if (permission('SFTH')) {
                    next();
                  } else {
                    next('/404');
                  }
                }
              },

              {
                path: 'vision-globale-frais',
                name: 'visionGlobaleFrais',
                meta: {
                  tab: 'tab-1'
                },
                component: () =>
                  import(
                    /*WebpackChunName: "visionGlobaleFrais" */ '../views/ThProject/frais/fraisDynamic/Frais.vue'
                  ),
                beforeEnter(to, from, next) {
                  if (permission('GGDFTH')) {
                    next();
                  } else {
                    next('/404');
                  }
                }
              }
            ]
          },
          {
            path: 'gestion-facture-mensuel',
            name: 'gestion-facture-mensuel',
            meta: {
              tab: 'tab-3'
            },
            component: () =>
              import(
                /*WebpackChunName: "gestionFactureMensuel" */ '../views/ThProject/frais/FactureMensuel/gestionFactureMensuel.vue'
              ),
            beforeEnter(to, from, next) {
              if (permission('GFMTH')) {
                next();
              } else {
                next('/404');
              }
            }
          },
          {
            path: 'master-filiale-2',
            name: 'masterFiliale-2',
            meta: {
              tab: 'tab-4'
            },
            component: () =>
              import(
                /*WebpackChunName: "masterFiliale2.0" */ '../views/ThProject/frais/masterFiliale/gestionMasterFiliale.vue'
              ),
            beforeEnter(to, from, next) {
              if (permission('GMFTH')) {
                next();
              } else {
                next('/404');
              }
            }
          },
          {
            path: 'comptabilite',
            name: 'comptabilite',
            component: () =>
              import(
                /*WebpackChunName: "Comptabilite" */ '../views/ThProject/comptabilité/index.vue'
              ),
            beforeEnter(to, from, next) {
              if (permission('COTH')) {
                next();
              } else {
                next('/404');
              }
            }
          },
          {
            path: 'facture-sci',
            name: 'facture-sci',
            meta: {
              tab: 'tab-2'
            },
            component: () =>
              import(
                /*WebpackChunName: "facture-sci" */ '../views/factureSci/index.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (permission('FSTH')) {
                next();
              } else {
                next('/404');
              }
            }
          },
          {
            path: 'reglement-facture',
            name: 'reglement-facture',
            component: () =>
              import(
                /*WebpackChunName: "reglement-facture" */ '../views/reglementFacture/index.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (permission('RFTH')) {
                next();
              } else {
                next('/404');
              }
            }
          },
          {
            path: 'refresh-fature',
            name: 'refresh-fature',
            component: () =>
              import(
                /*WebpackChunName: "refresh-fature" */ '../views/refreshFacture/refreshFacture.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (permission('REFFTH')) {
                next();
              } else {
                next('/404');
              }
            }
          },
          {
            path: 'commercial-facture',
            name: 'commercial-facture',
            component: () =>
              import(
                /*WebpackChunName: "commercial-facture" */ '../views/commercial/index.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (permission('RFTH')) {
                next();
              } else {
                next('/404');
              }
            }
          },
          {
            path: 'pixel-facture',
            name: 'pixel-facture',
            component: () =>
              import(
                /*WebpackChunName: "pixel-facture" */ '../views/ThProject/frais/FacturePixel/index.vue'
              ),
            meta: {
              tab: 'tab-6'
            },
            beforeEnter: (to, from, next) => {
              if (permission('GFPXTH')) {
                next();
              } else {
                next('/404');
              }
            }
          }
        ]
      },
      {
        path: '/setting',
        name: 'setting',
        component: () =>
          import(
            /* webpackChunkName: "setting" */ '../views/setting/Setting.vue'
          ),
        beforeEnter: (to, from, next) => {
          if (isUser()) {
            next();
          } else {
            next('/login');
          }
        },
        children: [
          {
            path: 'configurationMensuel',
            component: () =>
              import(
                /* webpackChunkName: "users-index" */ '../views/setting/configuration/index.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (
                permission('GCM') ||
                permission('GCMCPB') ||
                permission('GCMCPS')
              ) {
                next();
              } else {
                next('/404');
              }
            },
            children: [
              {
                path: '',
                name: 'gestion-configuration-societe-mensuel',
                component: () =>
                  import(
                    /* webpackChunkName: "users" */ '../views/setting/configuration/GestionConfigurationVendeur.vue'
                  ),
                beforeEnter: (to, from, next) => {
                  if (permission('GCM')) {
                    next();
                  } else {
                    next('/404');
                  }
                }
              },
              {
                path: 'gestion-configuration-bien-mensuel',
                name: 'gestion-configuration-bien-mensuel',
                component: () =>
                  import(
                    /* webpackChunkName: "permission" */ '../views/setting/configuration/GestionConfigurationBien.vue'
                  ),
                beforeEnter: (to, from, next) => {
                  if (permission('GCMCPB')) {
                    next();
                  } else {
                    next('/404');
                  }
                }
              },
              {
                path: 'gestion-configuration-service-mensuel',
                name: 'gestion-configuration-service-mensuel',
                component: () =>
                  import(
                    /* webpackChunkName: "permission" */ '../views/setting/configuration/GestionConfigurationService.vue'
                  ),
                beforeEnter: (to, from, next) => {
                  if (permission('GCMCPS')) {
                    next();
                  } else {
                    next('/404');
                  }
                }
              }
            ]
          },
          {
            path: 'configuration-paiement',
            component: () =>
              import(
                /* webpackChunkName: "users-index" */ '../views/setting/configurationPaiement/index.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (

                (permission('GTPHT') || permission('GCPHT'))
              ) {
                next();
              } else {
                next('/404');
              }
            },
            children: [
              {
                path: '',
                name: 'gestion-type-reglement',
                component: () =>
                  import(
                    /* webpackChunkName: "type-reglement" */ '../views/setting/configurationPaiement/GestionTypeReglement.vue'
                  ),
                beforeEnter: (to, from, next) => {
                  if (permission('GTPHT')) {
                    next();
                  } else {
                    next('/404');
                  }
                }
              },
              {
                path: 'gestion-conditions-paiement',
                name: 'gestion-conditions-paiement',
                component: () =>
                  import(
                    /* webpackChunkName: "conditions-paiement" */ '../views/setting/configurationPaiement/GestionConditionsPaiements.vue'
                  ),
                beforeEnter: (to, from, next) => {
                  if (permission('GCPHT')) {
                    next();
                  } else {
                    next('/404');
                  }
                }
              }
            ]
          },
          {
            path: 'gestion-des-biens',
            name: 'Gestion des biens',
            component: () =>
              import(
                /* webpackChunkName: "Gestion des biens" */ '../views/setting/GestionBiens.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (permission('GBHT')) {
                next();
              } else {
                next('/404');
              }
            }
          },

          {
            path: 'configurationfiche',
            name: 'configurationFiche',
            component: () =>
              import(
                /* webpackChunkName: "configurationFiche" */ '../views/setting/configurationFiche.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (permission('GFTH')) {
                next();
              } else {
                next('/404');
              }
            }
          },
          {
            path: 'personal-info',
            name: 'PersonelDataUser',
            component: () =>
              import(
                /* webpackChunkName: "PersonelDataUser" */ '../views/setting/InformationsPersonnelles.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (isUser()) {
                next();
              } else {
                next('/404');
              }
            }
          },
          {
            path: 'regie-info',
            name: 'DataRegie',
            component: () =>
              import(
                /* webpackChunkName: "DataRegie" */ '../views/setting/InfoRégie.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (isRegie()) {
                next();
              } else {
                next('/404');
              }
            }
          },
          {
            path: 'file',
            name: 'FileRegie',
            component: () =>
              import(
                /* webpackChunkName: "FileRegie" */ '../views/setting/ListFileRegie.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (isRegie()) {
                next();
              } else {
                next('/404');
              }
            }
          },

          {
            path: 'calender-th',
            name: 'calender-th',
            component: () =>
              import(
                /* webpackChunkName: "calender-th" */ '../views/setting/CalendrierTh.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (permission('CTTH')) {
                next();
              } else {
                next('/404');
              }
            }
          },
          {
            path: 'repartition-zone-th',
            name: 'repartition-zone-th',
            component: () =>
              import(
                /* webpackChunkName: "repartition-zone-th" */ '../views/setting/RepartitionZoneSettingTh.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (permission('RZTH')) {
                next();
              } else {
                next('/404');
              }
            }
          },
          {
            path: 'gestion-filiale-th',
            name: 'gestion-filiale-th',
            component: () =>
              import(
                /* webpackChunkName: "gestion-filiale-th" */ '../views/setting/GestionFilialeTh.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (permission('GS')) {
                next();
              } else {
                next('/404');
              }
            }
          },
          {
            path: 'gestion-client-passage',
            name: 'gestion-client-passage',
            component: () =>
              import(
                /* webpackChunkName: "gestion-client-passage" */ '../views/setting/GestionClientPassage.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (permission('GCP')) {
                next();
              } else {
                next('/404');
              }
            }
          },
          {
            path: 'gestion-banque-th',
            name: 'gestion-banque-th',
            component: () =>
              import(
                /* webpackChunkName: "gestion-banque-th" */ '../views/setting/gestionBank.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (permission('GGBA')) {
                next();
              } else {
                next('/404');
              }
            }
          },

          {
            path: 'gestion-configuration',
            component: () =>
              import(
                /* webpackChunkName: "gestion-configuration-index" */ '../views/setting/configFrais/index.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (permission('GLCTH') || permission('GDFC')) {
                next();
              } else {
                next('../views/setting/configFrais/index.vue');
              }
            },
            children: [
              {
                path: '',
                name: 'config-interface',
                component: () =>
                  import(
                    /* webpackChunkName: "config-interface" */ '../views/setting/configFrais/GestionConfiguration.vue'
                  ),
                beforeEnter: (to, from, next) => {
                  if (permission('GLCTH')) {
                    next();
                  } else {
                    next('/404');
                  }
                }
              },
              {
                path: 'famille',
                name: 'famille',
                component: () =>
                  import(
                    /* webpackChunkName: "permission" */ '../views/setting/configFrais/GestionFamille.vue'
                  ),
                beforeEnter: (to, from, next) => {
                  if (permission('GDFC')) {
                    next();
                  } else {
                    next('/404');
                  }
                }
              }
            ]
          },
          {
            path: 'gestion-categories',
            name: 'gestion-categories',
            component: () =>
              import(
                /* webpackChunkName: "gestion-categories" */ '../views/setting/GestionCategories.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (permission('GCATTH')) {
                next();
              } else {
                next('/404');
              }
            }
          },
          {
            path: 'gestion-type-societe',
            name: 'gestion-type-societe',
            component: () =>
              import(
                /* webpackChunkName: "gestion-categories" */ '../views/setting/GestionTypeSociete.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (permission('GTSFL')) {
                next();
              } else {
                next('/404');
              }
            }
          },
          {
            path: 'gestion-services',
            name: 'gestion-services',
            component: () =>
              import(
                /* webpackChunkName: "gestion-categories" */ '../views/setting/GestionServices.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (permission('GSHT')) {
                next();
              } else {
                next('/404');
              }
            }
          },

          {
            path: 'organisme-new-th',
            name: 'organisme-new-th',
            component: () =>
              import(
                /* webpackChunkName: "organisme-new-th" */ '../views/setting/GestionOrganismeThNew.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (permission('GOTH')) {
                next();
              } else {
                next('/404');
              }
            }
          },
          {
            path: 'users',
            component: () =>
              import(
                /* webpackChunkName: "users-index" */ '../views/setting/users/index.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (
                permission('GUTH') ||
                permission('GPHT') ||
                permission('GPRHT') ||
                permission('GPHTF')
              ) {
                next();
              } else {
                next('/404');
              }
            },
            children: [
              {
                path: '',
                name: 'users',
                component: () =>
                  import(
                    /* webpackChunkName: "users" */ '../views/setting/users/Users.vue'
                  ),
                beforeEnter: (to, from, next) => {
                  if (permission('GUTH')) {
                    next();
                  } else {
                    next('/404');
                  }
                }
              },
              {
                path: 'permissions',
                name: 'permission',
                component: () =>
                  import(
                    /* webpackChunkName: "permission" */ '../views/setting/users/Permission.vue'
                  ),
                beforeEnter: (to, from, next) => {
                  if (permission('GPHT')) {
                    next();
                  } else {
                    next('/404');
                  }
                }
              },
              {
                path: 'profils',
                name: 'role',
                component: () =>
                  import(
                    /* webpackChunkName: "permission" */ '../views/setting/users/Role.vue'
                  ),
                beforeEnter: (to, from, next) => {
                  if (permission('GPRHT')) {
                    next();
                  } else {
                    next('/404');
                  }
                }
              },
              {
                path: 'familles',
                name: 'familles',
                component: () =>
                  import(
                    /* webpackChunkName: "permission-familles" */ '../views/setting/users/PermissionFamille.vue'
                  ),
                beforeEnter: (to, from, next) => {
                  if (permission('GPHTF')) {
                    next();
                  } else {
                    next('/404');
                  }
                }
              },
              {
                path: 'gestion-societe-permission',
                name: 'gestion-societe-permission',
                component: () =>
                  import(
                    /* webpackChunkName: "gestion-societe-permission" */ '../views/setting/users/SocieteAtraiter.vue'
                  ),
                beforeEnter: (to, from, next) => {
                  if (permission('GPATTH')) {
                    next();
                  } else {
                    next('/404');
                  }
                }
              }
            ]
          },

          {
            path: 'gestion-produit',
            name: 'gestion-produit',
            component: () =>
              import(
                /* webpackChunkName: "gestion-produit" */ '../views/setting/GestionProduits.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (permission('GPTH')) {
                next();
              } else {
                next('/404');
              }
            }
          },

          {
            path: 'template-th',
            name: 'template-th',
            component: () =>
              import(
                /* webpackChunkName: "template-th" */ '../views/setting/gestionTemplate/GestionTemplateTh.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (permission('GTTH')) {
                next();
              } else {
                next('/404');
              }
            }
          },
          {
            path: 'groupe-template-th',
            name: 'groupe-template-th',
            component: () =>
              import(
                /* webpackChunkName: "groupe-template-th" */ '../views/setting/gestionTemplate/gestionGroupeTemplateTh.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (permission('GGPTTH')) {
                next();
              } else {
                next('/404');
              }
            }
          },
          {
            path: 'modele-export',
            name: 'modele-export',
            component: () =>
              import(
                /* webpackChunkName: "modele-export" */ '../views/setting/GestionModèleExport.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (permission('GMETH')) {
                next();
              } else {
                next('/404');
              }
            }
          },

          {
            path: 'vue',
            name: 'vue',
            component: () =>
              import(
                /* webpackChunkName: "vue" */ '../views/setting/vueDynamique.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (permission('GVTH')) {
                next();
              } else {
                next('/404');
              }
            }
          }
        ]
      }
    ]
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});
const isSuperAdmin = () => {
  return store.getters.isSuperAdminGetter;
};
const isRegie = () => {
  if (store.state.User && store.state.User.role == 'admin_regie') {
    return true;
  }
  return false;
};

const isCommercialRegie = () => {
  if (store.state.User && store.state.User.role == 'commercial_regie') {
    return true;
  }
  return false;
};

const isUser = () => {
  if (
    store.state.User &&
    (store.state.User.role == 'responsable_poseurs' ||
      store.state.User.role === 'responsable_depot' ||
      store.state.User.role === 'responsable_commercial' ||
      store.state.User.role === 'admin_regie' ||
      store.state.User.role === 'admin' ||
      store.state.User.role === 'commercial_regie' ||
      store.state.User.role === 'contabilite' ||
      store.state.User.role === 'responsable_filiale' ||
      store.state.User.role === 'responsable_controle_de_gestion' ||
      store.state.User.role === 'responsable_validation' ||
      store.state.User.role === 'responsable_support' ||
      store.state.User.role === 'responsable_finance')
  ) {
    return true;
  }
  return false;
};
const isCommerciaux = () => {
  if (
    store.state.User &&
    (store.state.User.role === 'Responsable planning' ||
      store.state.User.role === 'Commercial sedentaire' ||
      store.state.User.role === 'Admin GE' ||
      store.state.User.role === 'Commercial terrain' ||
      store.state.User.role === 'Pre visiteur' ||
      store.state.User.role === 'Poseur' ||
      store.state.User.role === 'Co poseur')
  ) {
    return true;
  }
  return false;
};
const permission = key => {
  return store.getters.checkPermission(key);
};

export default router;
