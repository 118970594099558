import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import store from '../store/index';
window.pusher = require('pusher-js');
window.Echo = new Echo({
  broadcaster: 'pusher',
  key: 'a531dcd950d5a7bcdd70',
  encrypt: true,
  cluster: 'eu'
});

const getnotificationForResponsableDepot = function() {
  window.Echo.channel('notification').listen(
    '.responsable_depot_' + store.state.User.id,
    function(e) {
      var newNotification = e.notif;
      store.commit('NEW_NOTIFICATION', newNotification);
    }
  );
};
const destroyed = function() {
  window.Echo.leave('notification');
};
export default {
  getnotificationForResponsableDepot,
  destroyed
};
